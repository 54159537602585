<template>
  <div :class="wrapperClasses" :style="wrapperStyles">
    <div class="image-input__preview" :style="previewStyles">
      <span v-if="!url"><slot /></span>
    </div>
    <input type="file" @change="handleChangeFile" accept="image/jpeg,image/png">
  </div>
</template>

<script>
  import { getFileUrl } from '@/utils/files'

  export default {
    name: 'LogoInput',
    props: {
      size: {
        type: Number,
        default: 100,
        validator(val) {
          return val > 10 && val < 3000
        }
      },
      vertical: Boolean,
      classes: [String, Array, Object],
      imageUrl: String,
    },
    data() {
      return {
        url: this.imageUrl
      }
    },
    watch: {
      imageUrl(val) {
        if (val) {
          this.url = val
        }
      }
    },
    computed: {
      wrapperClasses() {
        return ['image-input', { 'image-input-vertical': this.vertical }, this.classes]
      },
      wrapperStyles() {
        return this.vertical ? {
          height: `${this.size}px`,
        } : {
          width: `${this.size}px`,
        }
      },
      previewStyles() {
        const styles = {
          width: `${this.size}px`,
          height: `${this.size}px`,
        }
        if (this.url) {
          styles.backgroundImage = `url(${this.url})`
        }
        return styles
      }
    },
    methods: {
      handleChangeFile(e) {
        const file = e.target.files[0]
        this.$emit('input', { target: { value: file } })

        if (file) {
          getFileUrl(file, url => {
            this.url = url
          })
        } else {
          this.url = null
        }

        e.target.value = ''
      }
    }
  }
</script>

<style scoped lang="scss">

  .image-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;

    &__preview {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 10px;
      border-radius: 50%;
      border: 1px solid #000;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    input[type=file] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      cursor: pointer;

      &::-webkit-file-upload-button {
        cursor: pointer;
      }
    }

    &.image-input-vertical {
      flex-direction: row;

      .image-input__label {
        margin-left: 20px;
      }

      .image-input__preview {
        margin-bottom: 0;
      }
    }

    &.is-invalid {
      .image-input__preview {
        border-color: #e55353;
      }
    }
  }
</style>
