<template>
  <div class="d-lg-block d-none" :class="classNames">
    <slot />
  </div>

  <CAccordion
    :active-item-key="defaultOpen ? 1 : null"
    class="d-lg-none d-block"
  >
    <CAccordionItem :item-key="1">
      <CAccordionHeader>
        {{ title }}
      </CAccordionHeader>
      <CAccordionBody>
        <slot />
      </CAccordionBody>
    </CAccordionItem>
  </CAccordion>
</template>

<script>
export default {
  props: {
    title: { type: String, default: '' },
    defaultOpen: { type: Boolean, default: false },
    classNames: { type: String, default: '' },
  },
}
</script>
